/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.black {
  color: black !important;
  p {
    color: black !important;
  }
}

.form-validation {
  font-size: 0.7em;
  color: gray;
}

#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

.hello {
  color: black !important;
  font-size: 1.5;
}

.grey {
  color: gray;
}

#container a {
  text-decoration: none;
}

#image {
  width: 25%;
}
@media (min-width: 320px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  #image {
    width: 70%;
    padding-bottom: 5%;
  }
}
@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
  #image {
    width: 50%;
    padding-bottom: 5%;
  }
}
@media (min-width: 600px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  #image {
    width: 30%;
    padding-bottom: 5%;
  }
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}
// @media only screen and (max-width: 3200px) {
//   #image {
//     width: 30%;
//     padding-bottom: 5%;
//   }
// }

.addIcon {
  float: right;
  cursor: pointer;
}

.closeIcon {
  float: right;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
